interface Breakpoints {
  mobile: number
  tablet: number
  desktop: number
}

interface ResizeState {
  width: number
  height: number
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}

export function useResizeObserver(customBreakpoints?: Partial<Breakpoints>) {
  const defaultBreakpoints: Breakpoints = {
    mobile: 768,
    tablet: 1024,
    desktop: 1280,
  }

  const breakpoints = { ...defaultBreakpoints, ...customBreakpoints }

  const state = reactive<ResizeState>({
    width: 0,
    height: 0,
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  })

  const updateDimensions = (width: number, height: number) => {
    state.width = width
    state.height = height
    state.isMobile = width < breakpoints.mobile
    state.isTablet = width >= breakpoints.mobile && width < breakpoints.tablet
    state.isDesktop = width >= breakpoints.tablet
  }

  const observe = (element?: HTMLElement, callback?: () => void) => {
    const targetElement = element || document.documentElement
    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect
      updateDimensions(width, height)
      if (callback) {
        callback()
      }
    })

    resizeObserver.observe(targetElement)

    updateDimensions(
      targetElement.clientWidth || window.innerWidth,
      targetElement.clientHeight || window.innerHeight,
    )

    onUnmounted(() => {
      resizeObserver.disconnect()
    })

    return resizeObserver
  }

  return {
    ...toRefs(state),
    observe,
    breakpoints,
  }
}
