import type { ToolEnum } from '~/constants/drawing'

export interface PathItem {
  id: number
  type: ToolEnum
  path: string // SVG path string
  color: string
  opacity: number
  width: number // Stroke width
}

export enum HistoryActionTypeEnum {
  Add = 'add',
  Erase = 'erase',
  Clear = 'clear',
}

interface HistoryAction {
  type: HistoryActionTypeEnum
  data: {
    path?: PathItem
    rasterState: ImageData // Canvas state after the action
  }
}

export const useHistory = () => {
  const history = ref<HistoryAction[]>([])
  const currentIndex = ref(-1)

  const canUndo = computed(() => currentIndex.value >= 0)
  const canRedo = computed(() => currentIndex.value < history.value.length - 1)

  const pushAction = (action: HistoryAction) => {
    if (currentIndex.value < history.value.length - 1) {
      history.value = history.value.slice(0, currentIndex.value + 1)
    }

    history.value.push(action)
    currentIndex.value = history.value.length - 1
  }

  const pushAddPath = (path: PathItem, rasterState: ImageData) => {
    pushAction({
      type: HistoryActionTypeEnum.Add,
      data: {
        path,
        rasterState,
      },
    })
  }

  const pushErasePath = (path: PathItem, rasterState: ImageData) => {
    pushAction({
      type: HistoryActionTypeEnum.Erase,
      data: {
        path,
        rasterState,
      },
    })
  }

  const pushClear = (rasterState: ImageData) => {
    pushAction({
      type: HistoryActionTypeEnum.Clear,
      data: {
        rasterState,
      },
    })
  }

  const getCurrentState = () => {
    if (currentIndex.value >= 0) {
      return history.value[currentIndex.value]
    }
    return null
  }

  const undo = () => {
    if (!canUndo.value) { return null }

    currentIndex.value--

    if (currentIndex.value === -1) {
      return {
        type: HistoryActionTypeEnum.Clear,
        data: {
          rasterState: new ImageData(
            history.value[0].data.rasterState.width,
            history.value[0].data.rasterState.height,
          ),
        },
      }
    }

    return getCurrentState()
  }

  const redo = () => {
    if (!canRedo.value) { return null }
    currentIndex.value++
    return getCurrentState()
  }

  const initHistory = () => {
    history.value = []
    currentIndex.value = -1
  }

  const getCurrentPaths = () => {
    const paths: PathItem[] = []

    if (currentIndex.value === -1) { return paths }

    for (let i = 0; i <= currentIndex.value; i++) {
      const action = history.value[i]
      if (action.type === HistoryActionTypeEnum.Clear) {
        paths.length = 0
      } else if (
        (action.type === HistoryActionTypeEnum.Add || action.type === HistoryActionTypeEnum.Erase)
        && action.data.path
      ) {
        paths.push(action.data.path)
      }
    }

    return paths
  }

  return {
    pushAddPath,
    pushErasePath,
    pushClear,
    undo,
    redo,
    initHistory,
    canUndo,
    canRedo,
    getCurrentPaths,
  }
}
