<template>
  <div
    class="drawing-board-container"
  >
    <div
      class="drawing-board"
      ref="drawingBoardRef"
    >
      <NuxtImg
        v-if="targetImage"
        :src="targetImage"
        alt="preview-image"
        class="preview-image"
        :style="previewImageStyle"
        @load="updateSize"
      />
      <canvas
        class="mask-canvas"
        ref="maskCanvasRef"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import paper from 'paper'
import { ToolEnum } from '~/constants/drawing'
import { type PathItem, useHistory } from '~/composables/drawing/use-history'
import { usePaperCanvas } from '~/composables/drawing/use-paper-canvas'
import { useResizeObserver } from '~/composables/use-resize-observer'

const drawingBoardRef = ref<HTMLDivElement | null>(null)
const maskCanvasRef = ref<HTMLCanvasElement | null>(null)

const backgroundImage = ref<HTMLImageElement | null>(null)

const dialogStore = useDialogStore()
const { isPartialRenderDialogOpen } = storeToRefs(dialogStore)
const isImageSourceChanged = ref(false)
const isTargetImagesChanged = ref(false)

const canvasStore = useCanvasStore()
const { strokeColor, strokeWidth, strokeOpacity, selectedTool, targetImage, targetImages } = storeToRefs(canvasStore)
const currentPaths = ref<PathItem[]>([])
const scale = ref(1)
const originalSize = ref({
  width: 0,
  height: 0,
})
const scaledSize = ref({
  width: 0,
  height: 0,
})

const previewImageStyle = computed(() => ({
  width: scaledSize.value.width ? `${scaledSize.value.width}px` : '100%',
  height: scaledSize.value.height ? `${scaledSize.value.height}px` : '100%',
}))

const isInit = ref(false)

const drawingHistory = useHistory()

const {
  initializePaper,
  createTool,
  updateBrushStyle,
  clear,
  onPathComplete,
  updateCanvasSize,
  restoreRasterState,
  hasContent,
  dispose,
} = usePaperCanvas({
  initialStyle: {
    color: strokeColor.value,
    width: strokeWidth.value,
    opacity: strokeOpacity.value,
  },
  selectedTool,
  scale,
})

const initializeBackgroundImage = () => {
  return new Promise((resolve, reject) => {
    if (!targetImage.value) {
      resolve(null)
      return
    }

    const img = new Image()
    img.onload = () => {
      backgroundImage.value = img
      resolve(img)
    }
    img.onerror = (error) => {
      console.error('Error in initializeBackgroundImage:', error)
      reject(new Error('Failed to load image'))
    }
    img.src = targetImage.value
  })
}

const undo = () => {
  const previousState = drawingHistory.undo()
  if (previousState?.data.rasterState) {
    restoreRasterState(previousState.data.rasterState)
  }
}

const redo = () => {
  const nextState = drawingHistory.redo()
  if (nextState?.data.rasterState) {
    restoreRasterState(nextState.data.rasterState)
  }
}

const clearCanvas = () => {
  currentPaths.value = []
  clear()

  // Get current canvas state and save to history
  if (maskCanvasRef.value) {
    const ctx = maskCanvasRef.value.getContext('2d')
    if (ctx) {
      const imageData = ctx.getImageData(0, 0, maskCanvasRef.value.width, maskCanvasRef.value.height)
      drawingHistory.pushClear(imageData)
    }
  }
}

const initCanvas = () => {
  currentPaths.value = []
  clear()
  drawingHistory.initHistory()
  dispose()

  createTool()
  if (maskCanvasRef.value) {
    initializePaper(maskCanvasRef.value)
  }
}

const getRatio = ({
  containerWidth,
  containerHeight,
  originalWidth,
  originalHeight,
}: {
  containerWidth: number
  containerHeight: number
  originalWidth: number
  originalHeight: number
}) => {
  if (containerWidth <= 0 || containerHeight <= 0 || originalWidth <= 0 || originalHeight <= 0) { return 1 }

  const widthRatio = containerWidth / originalWidth
  const heightRatio = containerHeight / originalHeight

  return Math.min(widthRatio, heightRatio)
}

const { observe } = useResizeObserver()

const updateSize = () => {
  const img = document.querySelector('.preview-image') as HTMLImageElement
  if (!img || !backgroundImage.value || !drawingBoardRef.value) { return }

  const containerWidth = drawingBoardRef.value.clientWidth
  const containerHeight = drawingBoardRef.value.clientHeight
  const originalWidth = backgroundImage.value.naturalWidth
  const originalHeight = backgroundImage.value.naturalHeight

  if (containerWidth <= 0 || containerHeight <= 0 || originalWidth <= 0 || originalHeight <= 0) {
    return
  }

  originalSize.value = {
    width: originalWidth,
    height: originalHeight,
  }

  const newScale = getRatio({
    containerWidth,
    containerHeight,
    originalWidth,
    originalHeight,
  })

  scale.value = newScale

  // Calculate new dimensions maintaining aspect ratio
  const newWidth = Math.floor(originalWidth * newScale)
  const newHeight = Math.floor(originalHeight * newScale)

  scaledSize.value = {
    width: newWidth,
    height: newHeight,
  }

  // Update canvas size to match image
  if (maskCanvasRef.value) {
    maskCanvasRef.value.width = newWidth
    maskCanvasRef.value.height = newHeight
    maskCanvasRef.value.style.width = `${newWidth}px`
    maskCanvasRef.value.style.height = `${newHeight}px`

    nextTick(() => {
      updateCanvasSize(newWidth, newHeight)
    })
  }
}

// Path complete event handler
onPathComplete((pathData) => {
  if (!pathData) { return }

  const newPath: PathItem = {
    id: Date.now(),
    type: pathData.isEraser ? ToolEnum.Eraser : ToolEnum.Brush,
    path: pathData.pathData,
    color: strokeColor.value,
    opacity: strokeOpacity.value,
    width: strokeWidth.value,
  }

  if (pathData.isEraser) {
    drawingHistory.pushErasePath(newPath, pathData.rasterState)
  } else {
    currentPaths.value = [...currentPaths.value, newPath]
    drawingHistory.pushAddPath(newPath, pathData.rasterState)
  }
})

const generateMask = () => {
  if (!maskCanvasRef.value || !backgroundImage.value || !hasContent.value) { return }

  const { width: originalWidth, height: originalHeight } = originalSize.value

  const maskCanvas = document.createElement('canvas')
  const previewMaskCanvas = document.createElement('canvas')

  maskCanvas.width = originalWidth
  maskCanvas.height = originalHeight
  previewMaskCanvas.width = originalWidth
  previewMaskCanvas.height = originalHeight

  const maskCtx = maskCanvas.getContext('2d')
  const previewMaskCtx = previewMaskCanvas.getContext('2d')

  if (!maskCtx || !previewMaskCtx) { return }

  const currentValidPaths = drawingHistory.getCurrentPaths()

  currentValidPaths.filter(pathItem => pathItem.type !== ToolEnum.Eraser).forEach((pathItem) => {
    [maskCtx, previewMaskCtx].forEach((ctx) => {
      if (!ctx) { return }

      ctx.save()
      ctx.strokeStyle = pathItem.color
      ctx.lineWidth = pathItem.width / scale.value
      ctx.lineCap = 'round'
      ctx.lineJoin = 'round'
      ctx.globalAlpha = pathItem.opacity

      const path = new paper.Path(pathItem.path)

      ctx.beginPath()
      path.segments.forEach((segment, index) => {
        if (index === 0) {
          ctx.moveTo(segment.point.x, segment.point.y)
        } else {
          ctx.lineTo(segment.point.x, segment.point.y)
        }
      })
      ctx.stroke()
      ctx.restore()

      path.remove()
    })
  })

  currentValidPaths.filter(pathItem => pathItem.type === ToolEnum.Eraser).forEach((pathItem) => {
    [maskCtx, previewMaskCtx].forEach((ctx) => {
      if (!ctx) { return }

      ctx.save()
      ctx.globalCompositeOperation = 'destination-out'
      ctx.lineWidth = pathItem.width / scale.value
      ctx.lineCap = 'round'
      ctx.lineJoin = 'round'
      ctx.globalAlpha = 1

      const path = new paper.Path(pathItem.path)

      ctx.beginPath()
      path.segments.forEach((segment, index) => {
        if (index === 0) {
          ctx.moveTo(segment.point.x, segment.point.y)
        } else {
          ctx.lineTo(segment.point.x, segment.point.y)
        }
      })
      ctx.stroke()
      ctx.restore()

      path.remove()
    })
  })

  // Process mask data
  const maskImageData = maskCtx.getImageData(0, 0, originalWidth, originalHeight)
  const previewImageData = previewMaskCtx.getImageData(0, 0, originalWidth, originalHeight)

  const maskData = maskImageData.data
  const previewData = previewImageData.data

  // Fill black for mask and white for preview
  for (let i = 0; i < maskData.length; i += 4) {
    const alpha = maskData[i + 3]
    if (alpha > 0) {
      // Solid black for mask
      maskData[i] = 0
      maskData[i + 1] = 0
      maskData[i + 2] = 0
      maskData[i + 3] = 255

      // Semi-transparent white for preview
      previewData[i] = 255
      previewData[i + 1] = 255
      previewData[i + 2] = 255
      previewData[i + 3] = Math.round(255 * strokeOpacity.value)
    }
  }

  // Draw processed data back to canvases
  maskCtx.putImageData(maskImageData, 0, 0)
  previewMaskCtx.putImageData(previewImageData, 0, 0)

  // Convert to base64
  const maskBase64 = maskCanvas.toDataURL('image/png')
  const previewMaskBase64 = previewMaskCanvas.toDataURL('image/png')

  // Update store
  canvasStore.setMaskImage(maskBase64, previewMaskBase64, {
    width: originalWidth,
    height: originalHeight,
    scale: 1,
  })

  // Clean up temporary canvases
  maskCanvas.remove()
  previewMaskCanvas.remove()
}

const applyDrawings = () => {
  generateMask()
}

onMounted(async () => {
  await initializeBackgroundImage()

  if (!maskCanvasRef.value || !drawingBoardRef.value) { return }

  await nextTick()
  updateSize()

  initCanvas()

  observe(drawingBoardRef.value, () => {
    nextTick(() => {
      updateSize()
    })
  })
})

onUnmounted(() => {
  dispose()
})

watch([strokeColor, strokeWidth, strokeOpacity], ([color, width, opacity]) => {
  updateBrushStyle({
    color,
    width,
    opacity,
  })
}, { immediate: true })

watch(targetImage, async (newSource, oldSource) => {
  if (newSource !== oldSource) {
    isImageSourceChanged.value = true
  } else {
    isImageSourceChanged.value = false
  }
})

watch(targetImages, async (newTargetImages, oldTargetImages) => {
  const { after: newAfter } = newTargetImages
  const { after: oldAfter } = oldTargetImages
  if (newAfter !== oldAfter) {
    isTargetImagesChanged.value = true
  } else {
    isTargetImagesChanged.value = false
  }
}, { deep: true })

watch(isPartialRenderDialogOpen, async (isOpen) => {
  if ((isOpen && isImageSourceChanged.value && isTargetImagesChanged.value) || isInit.value) {
    isInit.value = false
    initCanvas()
    await initializeBackgroundImage()
    updateSize()
    isImageSourceChanged.value = false
    isTargetImagesChanged.value = false
  } else if (isOpen) {
    await nextTick()
    updateSize()
  }
})

canvasStore.$onAction(
  ({ name, after }) => {
    after(() => {
      if (name === 'initTargetImage') {
        isInit.value = true
      }
    })
  },
)

defineExpose({
  clearCanvas,
  initCanvas,
  applyDrawings,
  undo,
  redo,
  hasContent,
  canUndo: drawingHistory.canUndo,
  canRedo: drawingHistory.canRedo,
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

.drawing-board-container {
  width: 100%;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  .drawing-board {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;
    min-height: 0;

    .preview-image {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .mask-canvas {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 15px;
      z-index: 2;
    }
  }
}
</style>
