import paper from 'paper'
import { PointBasedTools, ToolEnum } from '~/constants/drawing'

type PathCompleteCallback = (pathData: {
  svgString: string
  pathData: string
  path: paper.Path
  isEraser?: boolean
  rasterState: ImageData
} | null) => void

interface BrushStyle {
  color: string
  width: number
  opacity: number
}

interface PaperCanvasOptions {
  initialStyle: BrushStyle
  selectedTool: Ref<ToolEnum>
  scale: Ref<number>
}

export const usePaperCanvas = (
  { initialStyle, selectedTool, scale }: PaperCanvasOptions,
) => {
  const isDrawing = ref(false)
  const hasContent = ref(false)
  let currentPath: paper.Path | null = null
  let tool: paper.Tool | null = null
  let pathCompleteHandler: PathCompleteCallback | null = null
  let pathPoints: paper.Point[] = []
  let lastPoint: paper.Point | null = null
  let rasterLayer: paper.Raster | null = null
  let dotCursor: paper.Path.Circle | null = null

  const currentStyle = {
    color: initialStyle.color,
    width: initialStyle.width,
    opacity: initialStyle.opacity,
  }

  // Check if canvas has any content
  const checkCanvasContent = () => {
    if (!rasterLayer) { return false }

    const ctx = rasterLayer.canvas.getContext('2d')
    if (!ctx) { return false }

    const imageData = ctx.getImageData(0, 0, rasterLayer.width, rasterLayer.height)
    return imageData.data.some((value, index) =>
      (index + 1) % 4 === 0 && value > 0,
    )
  }

  // Update cursor style based on selected tool
  const updateCursorStyle = () => {
    if (!dotCursor) { return }
    const isEraser = selectedTool.value === ToolEnum.Eraser

    const fillColor = new paper.Color(currentStyle.color)
    fillColor.alpha = 0.25

    dotCursor.fillColor = isEraser
      ? new paper.Color(1, 1, 1, 0.25)
      : fillColor

    dotCursor.strokeColor = isEraser
      ? new paper.Color(0, 0, 0)
      : new paper.Color(currentStyle.color)

    if (isEraser) {
      dotCursor.dashArray = [4, 4]
    }
  }

  // Create cursor dot
  const createDotCursor = (point: paper.Point) => {
    if (dotCursor) {
      dotCursor.remove()
    }

    dotCursor = new paper.Path.Circle({
      center: point,
      radius: currentStyle.width / 2,
      strokeWidth: 1,
      guide: true,
      data: {
        isDotCursor: true,
      },
    })

    updateCursorStyle()
  }

  // Update cursor dot position
  const updateDotCursor = (point: paper.Point) => {
    if (dotCursor && !isDrawing.value) {
      dotCursor.position = point
    }
  }

  // Watch for tool changes
  watch(selectedTool, () => {
    updateCursorStyle()
  })

  const initializePaper = (canvasElement: HTMLCanvasElement) => {
    canvasElement.style.backgroundColor = 'transparent'

    if (PointBasedTools.includes(selectedTool.value)) {
      canvasElement.style.cursor = 'crosshair'
    }

    paper.setup(canvasElement)
    paper.settings.handleSize = 0

    // Initialize raster layer
    rasterLayer = new paper.Raster({
      width: canvasElement.width,
      height: canvasElement.height,
    })
    rasterLayer.position = paper.view.center
  }

  const updateCanvasSize = (width: number, height: number) => {
    if (!paper.view || width <= 0 || height <= 0) { return }

    paper.view.viewSize = new paper.Size(width, height)

    if (rasterLayer) {
      const tempCanvas = document.createElement('canvas')
      const currentWidth = rasterLayer.width
      const currentHeight = rasterLayer.height
      tempCanvas.width = currentWidth
      tempCanvas.height = currentHeight

      const tempCtx = tempCanvas.getContext('2d')
      if (tempCtx) {
        tempCtx.drawImage(rasterLayer.canvas, 0, 0)
      }

      const newRaster = new paper.Raster({
        width: paper.view.viewSize.width,
        height: paper.view.viewSize.height,
      })
      newRaster.position = paper.view.center

      const ctx = newRaster.context
      if (ctx && tempCtx) {
        ctx.save()
        const scaleX = width / currentWidth
        const scaleY = height / currentHeight
        ctx.scale(scaleX, scaleY)
        ctx.drawImage(tempCanvas, 0, 0)
        ctx.restore()
      }

      rasterLayer.remove()
      rasterLayer = newRaster
    }

    paper.view.update()
  }

  const startDrawing = (event: paper.ToolEvent) => {
    isDrawing.value = true
    pathPoints = []
    lastPoint = event.point.clone()
    pathPoints.push(event.point.clone())

    if (dotCursor) {
      dotCursor.visible = false
    }

    if (selectedTool.value === ToolEnum.Brush) {
      currentPath = new paper.Path({
        segments: [event.point],
        strokeColor: new paper.Color(currentStyle.color),
        strokeWidth: currentStyle.width,
        strokeCap: 'round',
        strokeJoin: 'round',
        opacity: currentStyle.opacity,
        fillColor: null,
        selected: false,
      })
    } else if (selectedTool.value === ToolEnum.Eraser) {
      currentPath = new paper.Path({
        segments: [event.point],
        strokeWidth: currentStyle.width,
        strokeCap: 'round',
        strokeJoin: 'round',
        opacity: 1,
        selected: false,
        data: { isEraser: true },
      })
    }

    if (currentPath) {
      currentPath.smooth({ type: 'continuous' })
    }
  }

  const draw = (event: paper.ToolEvent) => {
    if (!isDrawing.value || !currentPath || !lastPoint || !rasterLayer) { return }

    const newPoint = event.point
    currentPath.add(newPoint)
    lastPoint = newPoint.clone()
    pathPoints.push(newPoint.clone())

    if (selectedTool.value === ToolEnum.Eraser) {
      // Apply eraser effect directly to raster layer
      const ctx = rasterLayer.canvas.getContext('2d')
      if (ctx) {
        ctx.save()
        ctx.globalCompositeOperation = 'destination-out'
        ctx.beginPath()
        ctx.arc(newPoint.x, newPoint.y, currentStyle.width / 2, 0, Math.PI * 2)
        ctx.fill()
        ctx.restore()

        const imageData = ctx.getImageData(0, 0, rasterLayer.width, rasterLayer.height)
        rasterLayer.setImageData(imageData)
      }
    }

    paper.view.update()
  }

  const handleEraserComplete = () => {
    if (!currentPath) { return }
    currentPath.remove()
  }

  const endDrawing = (event: paper.ToolEvent) => {
    if (!currentPath || !rasterLayer) { return }

    isDrawing.value = false
    if (dotCursor) {
      dotCursor.visible = true
      dotCursor.position = event.point
    }

    if (selectedTool.value === ToolEnum.Brush) {
      // Rasterize brush path
      const ctx = rasterLayer.canvas.getContext('2d')
      if (ctx) {
        ctx.save()
        ctx.strokeStyle = currentStyle.color
        ctx.lineWidth = currentStyle.width
        ctx.lineCap = 'round'
        ctx.lineJoin = 'round'
        ctx.globalAlpha = currentStyle.opacity

        ctx.beginPath()
        pathPoints.forEach((point, index) => {
          if (index === 0) {
            ctx.moveTo(point.x, point.y)
          } else {
            ctx.lineTo(point.x, point.y)
          }
        })
        ctx.stroke()
        ctx.restore()

        const imageData = ctx.getImageData(0, 0, rasterLayer.width, rasterLayer.height)
        rasterLayer.setImageData(imageData)

        const normalizedPoints = pathPoints.map((point) => {
          return new paper.Point(
            point.x / scale.value,
            point.y / scale.value,
          )
        })

        const pathForHistory = new paper.Path({
          segments: normalizedPoints,
          strokeColor: new paper.Color(currentStyle.color),
          strokeWidth: currentStyle.width / scale.value,
          strokeCap: 'round',
          strokeJoin: 'round',
          opacity: currentStyle.opacity,
        })

        const svgString = pathForHistory.exportSVG({ asString: true }) as string
        const pathData = pathForHistory.pathData

        if (pathCompleteHandler) {
          pathCompleteHandler({
            svgString,
            pathData,
            path: pathForHistory,
            rasterState: imageData,
          })
        }

        pathForHistory.remove()
        currentPath.remove()
      }
    } else if (selectedTool.value === ToolEnum.Eraser) {
      // Record eraser action in history
      const normalizedPoints = pathPoints.map((point) => {
        return new paper.Point(
          point.x / scale.value,
          point.y / scale.value,
        )
      })

      const eraserPath = new paper.Path({
        segments: normalizedPoints,
        strokeWidth: currentStyle.width / scale.value,
        strokeCap: 'round',
        strokeJoin: 'round',
      })

      const svgString = eraserPath.exportSVG({ asString: true }) as string
      const pathData = eraserPath.pathData

      // Save current raster state
      const ctx = rasterLayer.canvas.getContext('2d')
      if (ctx) {
        const imageData = ctx.getImageData(0, 0, rasterLayer.width, rasterLayer.height)

        if (pathCompleteHandler) {
          pathCompleteHandler({
            svgString,
            pathData,
            path: eraserPath,
            isEraser: true,
            rasterState: imageData,
          })
        }
      }

      eraserPath.remove()
      handleEraserComplete()
    }

    // Update hasContent after rasterLayer is updated
    hasContent.value = checkCanvasContent()

    currentPath = null
    pathPoints = []
    lastPoint = null

    paper.view.update()
  }

  const createTool = () => {
    const newTool = new paper.Tool()
    tool = newTool

    if (tool) {
      tool.minDistance = 2
      tool.maxDistance = 30
      tool.onMouseDown = startDrawing
      tool.onMouseDrag = draw
      tool.onMouseUp = endDrawing
      tool.onMouseMove = (event: paper.ToolEvent) => {
        if (!dotCursor) {
          createDotCursor(event.point)
        } else {
          updateDotCursor(event.point)
        }
      }
    }

    return tool
  }

  const updateBrushStyle = ({
    color,
    width,
    opacity,
  }: {
    color?: string
    width?: number
    opacity?: number
  }) => {
    if (color) {
      currentStyle.color = color
      updateCursorStyle()
    }
    if (width) {
      currentStyle.width = width
      if (dotCursor) {
        const point = dotCursor.position
        dotCursor.remove()
        createDotCursor(point)
      }
    }
    if (opacity !== undefined) { currentStyle.opacity = opacity }
  }

  const clear = () => {
    if (rasterLayer) {
      const ctx = rasterLayer.canvas.getContext('2d')
      if (ctx) {
        ctx.clearRect(0, 0, rasterLayer.width, rasterLayer.height)

        const imageData = ctx.getImageData(0, 0, rasterLayer.width, rasterLayer.height)
        rasterLayer.setImageData(imageData)
      }
    }
    hasContent.value = false
    paper.view?.update()
  }

  const onPathComplete = (callback: PathCompleteCallback) => {
    pathCompleteHandler = callback
  }

  const restoreRasterState = (imageData: ImageData) => {
    if (!rasterLayer) { return }

    const ctx = rasterLayer.canvas.getContext('2d')
    if (ctx) {
      // Clear canvas completely
      ctx.clearRect(0, 0, rasterLayer.width, rasterLayer.height)

      // Restore image data only if it exists
      if (imageData.data.some(value => value !== 0)) {
        ctx.putImageData(imageData, 0, 0)
      }

      // Update Paper.js raster layer
      rasterLayer.setImageData(ctx.getImageData(0, 0, rasterLayer.width, rasterLayer.height))
    }

    hasContent.value = checkCanvasContent()
    paper.view.update()
  }

  const dispose = () => {
    if (tool) {
      tool.remove()
      tool = null
    }
    if (dotCursor) {
      dotCursor.remove()
      dotCursor = null
    }
    if (rasterLayer) {
      rasterLayer.remove()
      rasterLayer = null
    }
    paper.project?.clear()
    hasContent.value = false
    scale.value = 1
  }

  return {
    initializePaper,
    createTool,
    updateBrushStyle,
    clear,
    onPathComplete,
    updateCanvasSize,
    restoreRasterState,
    hasContent,
    dispose,
  }
}
